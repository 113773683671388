<template>
  <div id="contact_us" class="footer-wrapper">
    <div class="footer-section-one">
      <div class="footer-logo-container">
        <img style="position: relative;left: 15px;" :src="logo" alt="V-SHAPE logo" />
      </div>
      <div class="small-text" style="position: relative;left: 15px;font-size: 14px;">
        V-SHAPE is a comprehensive product that seamlessly integrates calorie tracking, food ordering, and tailored weight management solutions, empowering users to achieve their desired weight goals through a streamlined and convenient platform.
      </div>
      <div class="footer-icons">
        <!-- Icons replaced with hypothetical Vue component or direct SVG use -->
        <font-awesome-icon icon="instagram" />
        <font-awesome-icon icon="youtube" />
        <font-awesome-icon icon="facebook-f" />
      </div>
    </div>
    <div class="footer-section-two">
      <div class="footer-section-columns">
        <span>Company</span>
        <span>Contact Us</span>
        <span>Pricing</span>
        <span>Team</span>
        <span>Solutions</span>
      </div>
      <div class="footer-section-columns">
        <span>+966 563 555 394</span>
        <span>Info@V-shape.sa</span>
      </div>
      <div class="footer-section-columns">
        <span>Terms & Conditions</span>
        <span>Privacy Policy</span>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/landing_page/logo-web.png';

export default {
  name: 'Footer',
  data() {
    return {
      logo,
    };
  },
};
</script>

<style scoped>
/* Your CSS goes here */
</style>
