<template>
  <nav>
    <div class="nav-logo-container">
      <img :src="logo" alt="logo" width="700px"/>
    </div>
    <div class="navbar-links-container">
      <a v-for="option in menuOptions" :key="option.text" :href="`#${option.path}`">{{ option.text }}</a>
      <a href="#">
        <button class="primary-button">Download App</button>
      </a>
    </div>
    <div class="navbar-menu-container">
      <button @click="toggleMenu">☰</button> <!-- Simplified icon representation -->
    </div>
    <div v-if="openMenu" class="drawer">
      <ul>
        <li v-for="item in menuOptions" :key="item.text">
          {{ item.text }}
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import logo from "@/assets/landing_page/logo-web.png"; // Adjust path as necessary

export default {
  name: 'Navbar',
  data() {
    return {
      openMenu: false,
      logo: logo,
      menuOptions: [
        { text: "Why V-shape?", path:'why_v_shape' },
        { text: "About Us", path:'about_us' },
        { text: "Pricing", path:'pricing' },
        { text: "Contact Us", path:'contact_us' },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.openMenu = !this.openMenu;
    },
  },
};
</script>

<style scoped>
/* Your CSS styles here, adjust as necessary */
</style>
