<template>
  <div id="why_v_shape" class="home-container">
    <div class="home-banner-container">
      <div class="home-bannerImage-container">
        <img :src="bannerBackground" alt="" loading="lazy" />
      </div>
      <div class="home-text-section">
        <h1 class="primary-heading">
          Your Path to Healthier Eating with optimized nutrition and our calorie tracker
        </h1>
        <p class="primary-text">
          A Comprehensive Food Delivery Tracking Platform to Optimize Your Nutrition, Manage Your Calories, and Achieve Your Wellness Goals Effortlessly
        </p>
      </div>
      <div class="home-image-section">
        <a style="cursor: pointer;"  href="#">
          <img :src="appStore"   alt="" />
        </a>
        <a style="cursor: pointer;"  href="https://play.google.com/store/apps/details?id=com.vshape.sa" target="_blank">
          <img :src="googleplay"  alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
 import bannerBackground from '@/assets/landing_page/web-background.jpg'; // Use @ for src directory
import appStore from '@/assets/landing_page/appStore.png';
import googleplay from '@/assets/landing_page/googleplay.png';

export default {
  name: 'Home',
  data() {
    return {
      bannerBackground,
      appStore,
      googleplay
    };
  }
};
</script>

<style scoped>
/* Include CSS here or import a CSS file */
</style>
