<template>
  <div id="pricing">
    <div  class="home-banner-container">
      <div class="featureImageContainer">
        <img :src="featuresBg" alt="" loading="lazy" />
      </div>
    </div>

    <div class="home-text-section">
      <h1 class="primary-heading">
        V-SHAPE Features
      </h1>
      <p class="primary-text">
        Try our calorie tracker and diet plan app that’s optimized for quicker and better weight loss results.
      </p>
    </div>

    <div class='features-container' v-for="feature in featuresList" :key="feature.title">
      <!-- Replace CheckCircleRoundedIcon with a Vue equivalent or an <img> tag -->
      <svg-icon name="check-circle" fontSize="large" /> <!-- Hypothetical Vue component for icons -->
      <div class='bigText1'>{{ feature.title }}</div>
      <div class='smallText'>{{ feature.txt }}</div>
    </div>
  </div>
</template>

<script>
import featuresBg from '@/assets/landing_page/featuresBg.png';

export default {
  name: 'Feature',
  data() {
    return {
      featuresBg,
      featuresList: [
        {
          title: "Meal Planning:",
          txt: " We provide personalized recommendations and tracking tools to help users achieve their dietary goals.",
        },
        {
          title: "Delicious Recipes:",
          txt: " Losing weight can be fun too, enjoy over 1000+ recipes",
        },
        {
          title: "Video Guidance:",
          txt: " Watch tutorial video on how to cook your favourite meal",
        },
        {
          title: "Nutritional Breakdown:",
          txt: " Each and every ingredient well calculated for you",
        },
        {
          title: "One-month Subscription:",
          txt: " Subscribe and receive your meals on your doorstep daily",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Your CSS goes here. Make sure paths in CSS match Vue asset handling if needed. */
</style>
