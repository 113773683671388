<template>
  <div id="about_us" class="about-section-container">
    <div class="about-section-image-container">
      <img :src="aboutBackground" alt="" />
    </div>
    <div class="about-section-text-container">
      <h1 class="primary-heading">
        Who We are & What We Do
      </h1>
      <p class="primary-text">
        We help our customers make informed choices about what they eat while also having the convenience of ordering meals that align with their dietary preferences, restrictions, or calorie goals.
      </p>
      <div class="work-section-bottom">
        <div v-for="data in workInfoData" :key="data.title" style="cursor: pointer;"
        @click="workInfoData[0].isSelected=false;workInfoData[1].isSelected=false;workInfoData[2].isSelected=false; data.isSelected = true;" 
        :class="data.isSelected === true ? 'work-section-info-black' : 'work-section-info'">
          <div class="info-boxes-img-container">
            <img :src="data.image" alt="" />
          </div>
          <div>
            <h3 :class="data.isSelected === true ? 'bigTextWhite' : 'bigText'">{{ data.title }}</h3>
            <p class="small-text">{{ data.text }}</p>
          </div>
          <!-- ArrowCircleRightIcon component or equivalent SVG goes here -->
        </div>
      </div>
      <div class="about-buttons-container">
        <button class="secondary-button">See More</button>
        <!-- Include BsFillPlayCircleFill icon or equivalent SVG here -->
        <button class="watch-video-button">
          Watch Video
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import aboutBackground from '@/assets/landing_page/chef.png';
import plate1 from '@/assets/landing_page/plate1.png';
import plate2 from '@/assets/landing_page/plate2.png';
import progress from '@/assets/landing_page/Progress circle.png';

export default {
  data() {
    return {
      aboutBackground,
      workInfoData: [
        {
          image: progress,
          title: "Calorie Tracking",
          isSelected: false,
          text: "This feature allows our customers to monitor their daily caloric intake. Users can input the foods they don’t consume, specifying portion sizes and meal times.",
        },
        {
          image: plate1,
          isSelected: true,
          title: "Food Delivery",
          text: "The app provides a platform for our customers to browse, select, and order meals from a variety of restaurants and food providers.",
        },
        {
          image: plate2,
          isSelected: false,
          title: "Personalized Meal Plans",
          text: "The app generates customized meal plans based on the user's dietary preferences, goals, and restrictions. These plans often consider factors like macronutrient ratios...",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Include your CSS here, ensuring it's scoped to this component */
</style>
