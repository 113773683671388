<template>
  <div class="App">
     <div className='home-container'>
      <Navbar></Navbar>
      <Home></Home>
    </div>
    <About></About>
    <Features></Features>
    <Contact_Us></Contact_Us>
    <Footer></Footer>
  </div>
</template>


<script>

import Navbar from "@/components/landing_page/Navbar.vue";
import Home from "@/components/landing_page/Home.vue";
import About from "@/components/landing_page/About.vue";
import Features from "@/components/landing_page/Features.vue";
import Contact_Us from "@/components/landing_page/Contact_Us.vue";
import Footer from "@/components/landing_page/Footer.vue";


export default {
  name: 'LandingPage',
  components: {
    Contact_Us,
    Footer,
    Navbar,
    Home,
    About,
    Features,
  },
  mounted() {
    // You may need to manually append script or do other actions once the component is mounted
  },
  // You can use the head method to include meta tags if you're using a plugin like vue-meta
  head() {
    return {
      title: 'V-SHAPE',
      meta: [
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {name: 'theme-color', content: '#000000'},
        {name: 'description', content: 'Web site created using Vue'},
        // ... other meta tags
      ],
      link: [
        {rel: 'icon', type: 'image/x-icon', href: '/favicon.ico'},
        {rel: 'apple-touch-icon', href: '/logo192.png'},
        {rel: 'manifest', href: '/manifest.json'},
        // ... other links like fonts
      ]
    };
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  max-width: 1900px;
  margin: 0rem auto;
}
/* For mobile devices */
@media (max-width: 600px) {
  .App {
    width: 85vw; /* Adjust as needed for mobile */
  }
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f6f6f6;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  position: fixed;
    background: #f8f8f8;
    width: 100%;
    z-index: 999;
}
.navbar-menu-container {
  display: none;
}
.nav-logo-container{
  max-width: 170px;
  position: relative;
  left: 15px;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 500;
}

.navbar-links-container a:hover {
  color: green;
  font-weight: bold;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: black;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s;
  color: white;

}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  padding-top: 3rem;
  flex-direction: row;


}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 1500px;
}
.featureImageContainer {
  position: absolute;
  /* top: 130px; */
  right: -160px;
  /* left: -100px; */
  max-width: 2000px;

}

.home-image-section {
  max-width: 400px;
  flex: 1;
  /* display: inline; */
  display: flex;
  align-items: center;

}

.home-image-section a:first-child{
    margin-left: 15px;
    margin-right: 15px;
  }

.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5rem;
}
.features-container {
  flex: 1;
  display: flex;
  margin-top: 2.5rem;
  color: #000;

}

.primary-heading {
  font-size: clamp(1.5rem,3vw, 4rem);
  color: black;
  /* line-height: 5rem; */
  max-width: 800px;
  margin-top: 5rem;
  position: relative;
  right: -15px

}
.centerBigTextHead {
  font-size: clamp(1.5rem,3vw, 4rem);
  color: black;
  /* line-height: 5rem; */
  text-align: center;
  margin-top: 7rem;
  font-weight: 600;
  align-items: center;

}
.row{
  display: flex;

}

.centerSmallTextHead {
  font-size: 1.5rem;
  color: black;

  text-align: center;
  margin-top: 7rem;
  font-weight: 300;
  align-items: center;

}
.primary-text {
  font-size: clamp(1rem, 2vw, 1.2rem);
  max-width: 700px;
  color: #666666;
  margin: 1.5rem 0rem;
  position: relative;
  right: -15px
}

.bigText{

  font-size: 1.2rem;
  font-weight: 600;
  font-style: normal;
  color: black;
}
.smallText{
  margin-top: 10px;
  margin-left: 2px;
}
.bigText1{
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 1rem;
  margin-top: 7px;

  color: black;

}
.bigTextWhite{
  font-size: 1.2rem;
  font-weight: 600;
  font-style: normal;
  color: white;
}
.small-text{
  margin-top: .75rem;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: normal;
  max-width: 400px;

}


.secondary-button {
  padding: 1rem 2.5rem;
  background-color: black;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: gray;
}
.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 1.5;
  margin-right: 2rem;
  margin-top: 5rem;
  margin-left: -6.3rem;
  min-width: 300px;


}

.feature-section-image-container {
  /* flex: 1.5; */

  margin-top: -20rem;

  /* min-width: 300px; */
  max-width: 600px;
  display: flex;

}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

}
.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}
.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  margin-top: 15rem;
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
  font-weight: 600;

}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 400;
  color: #4c4c4c;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 15rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  max-width: 700px;
  min-width: 400px;
  min-height: 100px;
  max-height: 180px;
  background-color: #E7E7E7;
  padding:  1rem;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  text-align: left;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}
.work-section-info-black {
  max-width: 700px;
  min-width: 400px;
  min-height: 100px;
  background-color: black;
  padding:  1rem;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  text-align: left;
  border-radius: 1rem;
  color: white;
  margin: 1rem 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.info-boxes-img-container{
  max-width: 80px;
  min-width: 80px;
  margin-right: 1rem;



}
@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 1200px;
  }
  .featureImageContainer {
    max-width: 1000px;
  }
  .home-image-section{
    max-width: 200px;
    
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 1200px;
  }
  .featureImageContainer {
    display: none;

  }  .home-image-section{
       max-width: 200px;

     }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

  }
  .about-section-container {
    flex-direction: column;
  }
  /* .home-image-section, */
  .about-section-image-container {
    width: 100%;
    max-width: 600px;
    min-width: 300px;
  }
  .primary-heading {
    text-align: left;
    max-width: 90%;
  }
  .primary-text {
    text-align: left;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;

  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    max-width: 95vw;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .featureImageContainer,
  .about-background-image-container {
    display: none;
  }
  .home-image-section{
    max-width: 200px;
    display: flex;
    align-items: center;


  }
}


*, html {

scroll-behavior: smooth !important;
}

</style>
