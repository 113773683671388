<template>
  <div class="contact-form-container">
    <form @submit.prevent="submitForm">
      <h2>Contact Us</h2>
      <div class="form-group">
        <label for="fullName">Full Name</label>
        <input type="text" id="fullName" v-model="fullName" required />
      </div>
      <div class="form-group">
        <label for="phoneNumber">Phone Number</label>
        <input type="tel" id="phoneNumber" v-model="phoneNumber" required />
      </div>
      <div class="form-group">
        <label for="message">Message</label>
        <textarea id="message" rows="5" v-model="message" required></textarea>
      </div>
      <button type="submit" style="background-color: #777777" class="primary-button">Submit</button>
    </form>
    <div v-if="formStatus" :class="{'success': formStatus === 'success', 'error': formStatus === 'error'}" class="form-status">
      {{ formMessage }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullName: '',
      phoneNumber: '',
      message: '',
      formStatus: '', // 'success' or 'error'
      formMessage: ''
    };
  },
  methods: {
    submitForm() {
      // Form validation
      if (!this.fullName || !this.phoneNumber || !this.message) {
        this.formStatus = 'error';
        this.formMessage = 'All fields are required.';
        return;
      }

      // Simulate form submission
      // Replace this with actual API call
      setTimeout(() => {
        // Assuming the form submission is successful
        this.formStatus = 'success';
        this.formMessage = 'Form submitted successfully!';

        // Reset form fields after submission
        this.fullName = '';
        this.phoneNumber = '';
        this.message = '';
      }, 500);
    }
  }
};
</script>

<style scoped>
.contact-form-container {
  background-color: white;
  max-width: 1200px;
  width: 100%;
  margin: 22% auto -9%;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form-container .form-group{
  width: 500%;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: block;
  color: #555;
}

input[type="text"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
}

button {
  width: 100%;
  padding: 0.9rem;
  background-color: #007BFF;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.form-status {
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
}

@media (max-width: 768px) {

  .contact-form-container .form-group{
    width: 100%;
  }
  .contact-form-container {
    padding: 1rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  button {
    font-size: 1rem;
  }
}
</style>
